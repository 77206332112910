import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { set_sync_status } from "../actions/Sync.js";
import SlotCalendar from "../components/Slots/SlotCalendar";
import Welcome from "../components/Welcome/Welcome.jsx";
import Dashboard from "./Dashboard/Dashboard.jsx";
import Menu from "./Menu/Menu.jsx";
import Settings from "./Settings/Settings.jsx";
import AddSlot from "./Slots/AddSlot.jsx";
import TeamManagement from "./Team/TeamManagement.jsx";
import Expenses from "./Expenses/ExpenseProject.jsx";
import NG from "./NG/NG.jsx";
import ExpensesPage from "./Expenses/ExpensesPage.jsx";

const Slotify = (props) => {

  // React Hooks
  const dispatch = useDispatch();
  const Sync = useSelector((state) => state.Sync);
  const location = useLocation();
  const canvasBox = null;

  // Periodically send message to sync slots asap
  const sync = useEffect(() => {
    dispatch(set_sync_status("asap"));

    let timer1 = setInterval(() => {
      dispatch(set_sync_status("asap"));
    }, 25000);

    return () => {
      setInterval(timer1)
    }
  }, []);

  const debug = (obj) => {

  }

  return (
    <>
      <Menu />

      {Sync.loginStatus === "offline" &&
        <Welcome />
      }

      {Sync.loginStatus === "loggedIn" &&
        <div className="">
          <Switch location={location}>
            <Route exact path="/">
              <Dashboard />
            </Route>

            <Route exact path="/dashboard">
              <Dashboard />
            </Route>

            <Route exact path="/team">
              <TeamManagement />
            </Route>

            <Route exact path="/calendar">
              <SlotCalendar />
            </Route>

            <Route exact path="/expenses">
              <ExpensesPage />
            </Route>

            <Route exact path="/ng">
              <NG />
            </Route>

            <Route exact path="/settings">
              <Settings />
            </Route>

            <Route exact path="/slots/add">
              <AddSlot />
            </Route>

            <Route exact path="/slots/edit/:slotId">
              <div className="container mt-5">
                <AddSlot />
              </div>
            </Route>

            <Route path="/about"></Route>
          </Switch>

        </div>
      }

    </>
  );
};

export default Slotify;